<template>
  <div>

  <NGrid :x-gap="isPage ? 15 : 0" :col="24">
    <NGi :span="6">
      <LabelList
        ref="labelListRef"
        label-list-title="标签列表"
        :label-type="resourceEnum.TEACHING_RESOURCE_TYPE"
        show-no-label
        @list-update="handlePageChange(1);"

        :label-content-height="labelContentHeight"
      />
    </NGi>
    <NGi :span="18">
      <NCard size="medium" :bordered="false">
        <SearchForm ref="searchFormRef" @list-update="handlePageChange(1);" />
        <div v-if="isPage" style="margin-bottom: 15px;">
          <NButton
            v-if="ownUrlPermission('teaching/batch-delete')"
            :disabled="selectedIdsArr.length === 0"
            @click="toDel('multiple')"
          >批量删除</NButton>
          <NDropdown
            width="trigger"
            trigger="click"
            :options="addBtnOptions"
            @select="handleAdd"
          >
            <NButton
              v-if="ownUrlPermission('teaching/create')"
              style="float: right; margin-left: 15px;"
              type="primary"
              icon-placement="right"
            >
              <template #default>新增资源</template>
              <template #icon>
                <NIcon color="#fff" size="18">
                  <ChevronDown />
                </NIcon>
              </template>
            </NButton>
          </NDropdown>
          <NButton
            v-if="ownUrlPermission('teaching/distribute')"
            style="float: right;"
            @click="toDistribute"
          >分发</NButton>
        </div>
        <NAlert
          v-show="selectedIdsArr.length > 0"
          style="margin-bottom: 10px;"
          type="primary"
        >
          已选择 <span class="theme-color">{{ selectedIdsArr.length }}</span> 项数据
        </NAlert>
        <NDataTable
          :columns="columns"
          :data="tableData"
          :row-key="row => row.id"
          remote
          :pagination="pagination"
          @update:page="handlePageChange"
          @update:page-size="handlePageSizeChange"
          v-model:checked-row-keys="selectedIdsArr"

          :max-height="tableContentHeight"
        />
      </NCard>
    </NGi>
  </NGrid>

  <Distribute
    ref="distributeRef"
    distribute-title="分发"
    :label-type="resourceEnum.TEACHING_RESOURCE_TYPE"
    :distribute-fn="distributeTeaching"
  />
  <PageLoading :loading="loading" />
  
  </div>
</template>

<script setup>
  import { ref, reactive, h, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import { NTime, NSpace, NButton } from 'naive-ui';
  import { ChevronDown } from '@vicons/ionicons5';

  import PageLoading from '@/components/PageLoading/index.vue';
  import LabelList from '@/components/LabelList/LabelList.vue';
  import SearchForm from './components/SearchForm.vue';
  import Distribute from '@/components/Distribute/Distribute.vue';

  import teachingTypesMap from '@/enumerators/teaching-types-map.js';
  import { getTeachingList, delTeaching, batDelTeaching, distributeTeaching } from '@/api/teaching.js';
  import { resStatusEnum } from '@/enumerators/http.js';
  import { resourceEnum } from '@/enumerators/resource-types-map.js';
  import ownUrlPermission from '@/utils/own-url-permission.js';
  
  const props = defineProps({
    isPage: {
      type: Boolean,
      default: false
    },
    filterIds: {
      type: Array,
      default: () => []
    },

    labelContentHeight: {
      type: String,
      default: 'auto'
    },
    tableContentHeight: {
      type: String,
      default: 'auto'
    }
  });

  const { SUCCESS } = resStatusEnum;
  const loading = ref(false);
  const labelListRef = ref(null);
  const searchFormRef = ref(null);

  const columns = [
    { type: 'selection' },
    { title: '标题', key: 'title' },
    {
      title: '类型',
      render: row => h(
        'span',
        null,
        getTeachingTypeName(row.type)
      )
    },
    { title: '创建者', key: 'admin_username' },
    {
      title: '更新日期',
      width: 190,
      render: row => h(
        NTime,
        {
          time: (Number(row.update_time) || 0) * 1000
        }
      )
    },
  ];
  if (props.isPage) {
    columns.push({
      title: '操作',
      width: 160,
      render: row => h(
        NSpace,
        null,
        {
          default: () => {
            const tempArr = [];
            if (ownUrlPermission('teaching/detail')) {
              tempArr.push(h(NButton, { text: true, type: 'primary', tag: 'a', target: '_blank', href: `/full-page/teaching-preview?id=${ row.id }` }, { default: () => '查看' }));
            }
            if (ownUrlPermission('teaching/update')) {
              tempArr.push(h(NButton, { text: true, type: 'primary', onClick: () => handleEdit(row) }, { default: () => '编辑' }));
            }
            if (ownUrlPermission('teaching/delete')) {
              tempArr.push(h(NButton, { text: true, type: 'error', onClick: () => toDel('single', row) }, { default: () => '删除' }));
            }
            return tempArr;
          }
        }
      )
    });
  }

  const tableData = ref([]);
  const pagination = reactive({
    page: 1,
    itemCount: 0,
    pageSize: 10,
    pageSizes: [10, 20, 30],
    showSizePicker: true
  });
  
  const updateItemCount = (count) => {
    pagination.itemCount = Number(count) || 0;
  };
  const handlePageChange = (page) => {
    pagination.page = page;
    updateList();
  };
  const handlePageSizeChange = (pageSize) => {
    pagination.pageSize = pageSize;
    handlePageChange(1);
  };
  const getTeachingTypeName = type => teachingTypesMap[type];
  const getReqParams = () => {
    const params = {
      ...searchFormRef.value.getSearchParams(),
      page: pagination.page,
      page_size: pagination.pageSize
    };
    const ids = labelListRef.value.getSelectedIds();
    if (ids.includes('0')) { // “未设置标签”
      params['Teaching[labels_num]'] = 0;
    } else {
      const labelsStr = ids.join(',');
      labelsStr && (params['Teaching[labels]'] = labelsStr);
    }
    excludeTeachingIds.length > 0 && (params['Teaching[ids]'] = excludeTeachingIds.join(','));
    return params;
  };
  const updateList = () => {
    loading.value = true;
    getTeachingList(getReqParams()).then(res => {
      loading.value = false;
      if (res.code === SUCCESS) {
        tableData.value = res.data.list || [];
        updateItemCount(res.data.total);

        updateIdTeachingMap(res.data.list);
      }
    }).catch(err => {
      loading.value = false;
    });
  }

  const selectedIdsArr = ref([]);
  const clearSelectedIds = () => {
    selectedIdsArr.value.splice(0);
  };
  const toDel = (type, singleRow) => {
    const dialog = window.$dialog;
    const message = window.$message;
    switch (type) {
      case 'single':
        const { id, title } = singleRow;
        dialog.warning({
          title: '删除确认',
          content: `确定删除资源“${title}”？`,
          positiveText: '确定',
          negativeText: '取消',
          onPositiveClick: () => {
            loading.value = true;
            delTeaching({ 'Teaching[id]': id }).then(res => {
              loading.value = false;
              if (res.code === SUCCESS) {
                message.success('删除成功');
                handlePageChange(1);
                labelListRef.value.updateLabelList();
                clearSelectedIds();
              }
            }).catch(err => {
              loading.value = false;
            });
          },
          onNegativeClick: () => {}
        });
        break;
      case 'multiple':
        const ids = selectedIdsArr.value;
        dialog.warning({
          title: '删除确认',
          content: `确定删除${ids.length}个资源？`,
          positiveText: '确定',
          negativeText: '取消',
          onPositiveClick: () => {
            loading.value = true;
            batDelTeaching({ 'Teaching[ids]': ids }).then(res => {
              loading.value = false;
              if (res.code === SUCCESS) {
                message.success('批量删除成功');
                handlePageChange(1);
                labelListRef.value.updateLabelList();
                clearSelectedIds();
              }
            }).catch(err => {
              loading.value = false;
            });
          },
          onNegativeClick: () => {}
        })
        break;
    }
  }

  onMounted(() => {
    if (props.isPage) {
      excludeTeachingIds.splice(0);
      updateList();
    }
  });

  const router = useRouter();
  const addBtnOptions = Object.keys(teachingTypesMap).map(key => ({ label: teachingTypesMap[key], key }));
  const handleAdd = key => {
    router.push(`add?type=${ key }`);
  };
  const handleEdit = ({ id, type }) => {
    router.push(`edit?type=${ type }&id=${ id }`);
  };

  const distributeRef = ref(null);
  const toDistribute = () => {
    distributeRef.value.openModal();
  };

  const idTeachingMap = {};
  const updateIdTeachingMap = resData => {
    if (Array.isArray(resData)) {
      resData.forEach(({ id, title }) => {
        idTeachingMap[id] = {
          id,
          title
        };
      });
    }
  };

  const getSelectedTeachings = () => {
    const tempList = [];
    selectedIdsArr.value.map(id => {
      if (idTeachingMap[id]) {
        tempList.push(idTeachingMap[id]);
      }
    });
    return tempList;
  };
  const excludeTeachingIds = [];
  const initData = ({ filterIds = [] } = {}) => {
    excludeTeachingIds.splice(0);
    excludeTeachingIds.push(...filterIds);
    handlePageChange(1);
  };

  defineExpose({
    getSelectedTeachings,
    initData
  });
</script>

<style lang="less" scoped>
  @import "~@/styles/variables.less";
  .theme-color {
    color: @theme-color;
  }
</style>
<template>
  <div style="padding: 15px; border: 1px solid #eee;">
    <NInput
      style="margin-bottom: 12px;"
      maxlength="20"
      show-count
      clearable
      placeholder="请输入章/节名称进行搜索"
      v-model:value="pattern"
    />
    <NTree
      class="tree"
      block-line
      :cancelable="false"
      label-field="title"
      :children-field="CHILDREN_FIELD"
      :pattern="pattern"
      :draggable="!readOnly && !editingNode"
      :render-label="labelRenderFn"
      :data="dirData"
      @update:selected-keys="handleSelect"
      @dragstart="handleDragStart"
      :allow-drop="handleAllowDrop"
      @drop="handleDrop"

      :expanded-keys="expandedKeys"
      @update:expanded-keys="handleExpandedKeysChange"
    ></NTree>
  </div>
</template>

<script setup>
  import { ref, h, withDirectives, resolveDirective } from 'vue';
  import { NSpace, NButton, NIcon, NInput } from 'naive-ui';
  import { EditOutlined, MinusOutlined, PlusOutlined, ClearOutlined, CheckOutlined } from '@vicons/material';

  import {
    generateChapterItem,
    generateSectionItem
  } from './tool-fns.js';

  const LABEL_FIELD = 'title';
  const CHILDREN_FIELD = 'section_list';

  const props = defineProps({
    readOnly: {
      type: Boolean,
      default: false
    },
    dirData: {
      type: Array,
      default: () => []
    }
  });
  const emit = defineEmits(['editing-section-change']);

  const pattern = ref('');

  const updateDirDataItemTags = () => {
    props.dirData.forEach(chapterItem => {
      chapterItem.hideDel = props.dirData.length < 2;
      if (Array.isArray(chapterItem[CHILDREN_FIELD])) {
        const length = chapterItem[CHILDREN_FIELD].length;
        chapterItem[CHILDREN_FIELD].forEach(sectionItem => {
          sectionItem.hideDel = length < 2;
        });
      }
    });
  };

  let dragNodeLv;
  let dragNodeIsOnlyChild = false;
  const editingNode = ref(null);
  let srcNodeLabel = '';
  const labelRenderFn = ({ option }) => {
    const label = option[LABEL_FIELD];

    const clickoutside = resolveDirective('clickoutside');

    return withDirectives(
      h(
        'div',
        { class: editingNode.value === option ? 'editing' : '' },
        [
          h(
            'div',
            { class: 'item-show' },
            h(
              NSpace,
              { size: [30, 0], wrap: false },
              {
                default: () => {
                  const tempVDomList = [
                    h('div', null, label)
                  ];
                  if (!props.readOnly) {
                    tempVDomList.push(h(
                      'div',
                      { class: 'item-btns' },
                      h(
                        NSpace,
                        { size: [5, 0], wrap: false },
                        {
                          default: () => [
                            h(NButton, { text: true, onClick: e => { e.stopPropagation(); cancelItem(); editItem(option); } }, { icon: () => h(NIcon, null, { default: () => h(EditOutlined) }) }),
                            h(NButton, { text: true, style: { display: option.hideDel ? 'none' : 'inline-flex' }, onClick: e => { e.stopPropagation(); delItem(option) } }, { icon: () => h(NIcon, null, { default: () => h(MinusOutlined) }) }),
                            h(NButton, { text: true, onClick: e => { e.stopPropagation(); addItem(option); } }, { icon: () => h(NIcon, null, { default: () => h(PlusOutlined) }) }),
                          ]
                        }
                      )
                    ));
                  }
                  return tempVDomList;
                }
              }
            )
          ),
          h(
            'div',
            { class: 'item-edit' },
            h(
              NSpace,
              { size: [20, 0], wrap: false },
              {
                default: () => [
                  h(
                    NInput,
                    {
                      size: 'small',
                      maxlength: '20',
                      showCount: true,
                      clearable: true,
                      value: label,
                      'onUpdate:value': value => (option[LABEL_FIELD] = value),
                      onClick: e => { e.stopPropagation(); },
                      onKeyup: e => { e.code === 'Enter' && confirmItem(option); }
                    }
                  ),
                  h(
                    NSpace,
                    { size: [5, 0], wrap: false },
                    {
                      default: () => [
                        h(NButton, { text: true, onClick: e => { e.stopPropagation(); cancelItem(option); } }, { icon: () => h(NIcon, null, { default: () => h(ClearOutlined) }) }),
                        h(NButton, { text: true, onClick: e => { e.stopPropagation(); confirmItem(option); } }, { icon: () => h(NIcon, null, { default: () => h(CheckOutlined) }) })
                      ]
                    }
                  )
                ]
              }
            )
          )
        ]
      ),
      [
        [clickoutside, () => { editingNode.value === option && confirmItem(option); }]
      ]
    );
  };
  const handleSelect = ([key], [option]) => {
    switch (key.split('-')[0]) {
      case '1':
        emit('editing-section-change', {
          data: option,
          chapterSectionType: 'chapter'
        });
        break;
      case '2':
        emit('editing-section-change', {
          data: option,
          chapterSectionType: 'section'
        });
        break;
    }
  };
  const findWrapAndIndex = (node, nodes) => {
    if (nodes && Array.isArray(nodes)) {
      for (let i = 0, l = nodes.length; i < l; i++) {
        const siblingNode = nodes[i];
        if (siblingNode.key === node.key) {
          return [nodes, i];
        } else {
          const [wrap, index] = findWrapAndIndex(node, siblingNode[CHILDREN_FIELD]);
          if (wrap) {
            return [wrap, index];
          }
        }
      }
      return [null, null];
    } else {
      return [null, null];
    }
  };
  const handleDragStart = ({ node }) => {
    const [dragNodeWrap] = findWrapAndIndex(node, props.dirData);
    dragNodeIsOnlyChild = dragNodeWrap.length < 2;
    dragNodeLv = node.key.split('-')[0];
  };
  const handleAllowDrop = ({ node, dropPosition }) => {
    if (dragNodeIsOnlyChild) {
      return false;
    }
    const [nodeLv] = node.key.split('-');
    if (nodeLv === '2' && dropPosition === 'inside') {
      return false;
    } else {
      switch (dragNodeLv) {
        case '1':
          switch (dropPosition) {
            case 'inside':
              return false;
            case 'before':
            case 'after':
              return nodeLv === '1';
          }
          break;
        case '2':
          switch (dropPosition) {
            case 'inside':
              return true;
            case 'before':
            case 'after':
              return nodeLv !== '1';
          }
          break;
        default:
          return false;
      }
    }
  };
  const handleDrop = ({ node, dragNode, dropPosition }) => {
    const [dragNodeWrap, dragNodeIndex] = findWrapAndIndex(
      dragNode,
      props.dirData
    );
    dragNodeWrap.splice(dragNodeIndex, 1);
    switch (dropPosition) {
      case 'inside':
        if (node[CHILDREN_FIELD]) {
          node[CHILDREN_FIELD].unshift(dragNode);
        } else {
          node[CHILDREN_FIELD] = [dragNode];
        }
        break;
      case 'before':
      case 'after':
        const [nodeWrap, nodeIndex] = findWrapAndIndex(
          node,
          props.dirData
        );
        nodeWrap.splice(
          nodeIndex + Number(dropPosition === 'after'),
          0,
          dragNode
        );
        break;
    }

    updateDirDataItemTags();
  };

  const updateNodeKey = node => {
    const keyArr = node.key.split('-');
    keyArr.pop();
    keyArr.push(Date.now());
    node.key = keyArr.join('-');
  };
  const editItem = node => {
    srcNodeLabel = node[LABEL_FIELD];
    editingNode.value = node;

    // updateNodeKey(node);
  };
  const addItem = node => {
    const [wrap, index] = findWrapAndIndex(node, props.dirData);
    let newNode;
    const lv = node.key.split('-')[0];
    switch (lv) {
      case '1':
        newNode = generateChapterItem();
        break;
      case '2':
        newNode = generateSectionItem();
        break;
    }
    wrap.splice(index + 1, 0, newNode);

    updateDirDataItemTags();
  };
  const delItem = node => {
    const [wrap, index] = findWrapAndIndex(node, props.dirData);
    wrap.splice(index, 1);

    updateDirDataItemTags();
  };
  const cancelItem = (node = editingNode.value) => {
    if (!!node) {
      node[LABEL_FIELD] = srcNodeLabel;
      editingNode.value = null;
      
      // updateNodeKey(node);
    }
  };
  const confirmItem = node => {
    node[LABEL_FIELD].trim() || (node[LABEL_FIELD] = srcNodeLabel);
    editingNode.value = null;

    // updateNodeKey(node);
  };

  const expandedKeys = ref([]);
  const handleExpandedKeysChange = (keys, option) => {
    expandedKeys.value = keys;
  };

  defineExpose({
    updateDirDataItemTags,
    cancelItem,
    expandAllChapters: () => {
      expandedKeys.value = props.dirData.map(item => item.key);
    }
  });
</script>

<style lang="less" scoped>
  .tree ::v-deep(.item-btns) {
    display: none;
  }

  .tree ::v-deep(.n-button) {
    vertical-align: top;
    margin-top: 6px;
  }

  .tree ::v-deep(.n-tree-node-switcher) {
    height: 30px;
  }
  .tree ::v-deep(.n-tree-node-content) {
    line-height: 30px;
  }

  .tree ::v-deep(.n-tree-node-content:hover .item-btns) {
    display: block;
  }

  .tree ::v-deep(.editing .item-show),
  .tree ::v-deep(.item-edit) {
    display: none;
  }
  .tree ::v-deep(.editing .item-edit) {
    display: block;
  }
</style>